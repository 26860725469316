import ky from 'ky'

export function get(reviewId) {
  return ky
    .get(
      `https://${process.env.REACT_APP_LINKIO_API}/api/provider/reviews/link/${reviewId}`
    )
    .json()
}

export function post({ reviewId, reviewerName, rating, reviewText, files }) {
  const formData = new FormData()
  formData.append('reviewId', reviewId)
  formData.append('reviewerName', reviewerName)
  formData.append('rating', rating)
  formData.append('reviewText', reviewText)

  if (files) {
    files.map((file) => formData.append('media[]', file))
  }

  return ky.post(
    `https://${process.env.REACT_APP_LINKIO_API}/api/provider/reviews/${reviewId}`,
    { body: formData }
  )
}

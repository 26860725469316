import logo from '../img/linkio-logo.png'

const LinkioHeader = () => (
  <div>
    <img
      className="mx-auto"
      src={logo}
      alt="Linkio Logo"
      width="90px"
      height="90px"
    />
  </div>
)

export default LinkioHeader


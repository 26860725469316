import LinkioHeader from './LinkioHeader'
import ReviewApp from './ReviewApp'

const App = () => (
  <div className="md:container md:mx-auto px-8 py-2">
    <LinkioHeader />
    <ReviewApp />
  </div>
)

export default App

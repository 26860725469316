const ShowMedia = (props) => {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <div className="flex">
        <label
          htmlFor="cover_photo"
          className="block text-sm font-semibold text-gray-700 sm:mt-px sm:pt-2"
        >
          Job photos
        </label>
        <button
          className="ml-1 sm:mt-2 lg:mt-2 w-4 text-indigo-600"
          onClick={props.onDelete}
          disabled={props.disabled}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </button>
      </div>

      <div className="mt-2">
        <div className="px-2">
          <div className="flex -mx-2">
            {props.files.map((file) => (
              <div key={file.name} className="w-1/3 px-2">
                <img
                  src={URL.createObjectURL(file)}
                  alt="..."
                  className="shadow-lg rounded max-w-full h-auto align-middle border-none"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShowMedia

import { useState } from 'react'

import FileUpload from './FileUpload'
import ShowMedia from './ShowMedia'
import StarsReview from './StarsReview'

const ReviewForm = (props) => {
  const [reviewerName, setReviewerName] = useState('')
  const [rating, setRating] = useState(5)
  const [reviewText, setReviewText] = useState('')
  const [files, setFiles] = useState([])
  const [isPosting, setIsPosting] = useState(false)

  return (
    <form
      className="space-y-8 divide-y divide-gray-200"
      onSubmit={(e) => {
        e.preventDefault()
        setIsPosting(true)
        props.onSubmit({ reviewerName, rating, reviewText, files })
      }}
    >
      <div>
        <div>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="flex items-center">
              <span className="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                {props.avatar && props.avatar.length > 0 ? (
                  <img src={props.avatar} alt="avatar" />
                ) : (
                  <svg
                    className="h-full w-full text-gray-300"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                )}
              </span>
            </div>
          </div>
          <h3 className="text-lg leading-6 font-medium text-gray-900 pt-4">
            {props.providerName}
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Please help me get started on Linkio by leaving me a review.
          </p>
        </div>

        <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="username"
              className="block text-sm font-semibold text-gray-700 sm:mt-px sm:pt-2"
            >
              Enter your name
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <div className="max-w-lg flex rounded-md shadow-sm">
                <input
                  type="text"
                  autoComplete="Enter your name"
                  className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-300"
                  required
                  value={reviewerName}
                  onChange={(e) => setReviewerName(e.target.value)}
                  disabled={isPosting}
                />
              </div>
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="username"
              className="block text-sm font-semibold text-gray-700 sm:mt-px sm:pt-2"
            >
              Rate
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <StarsReview
                rating={rating}
                onRatingChange={(newRating) => !isPosting && setRating(newRating)}
              />
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="about"
              className="block text-sm font-semibold text-gray-700 sm:mt-px sm:pt-2"
            >
              Review
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                rows="3"
                className="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                required
                value={reviewText}
                onChange={(e) => setReviewText(e.target.value)}
                disabled={isPosting}
              ></textarea>
              <p className="mt-2 text-sm text-gray-500">
                Write a few sentences about {props.providerName}.
              </p>
            </div>
          </div>

          {files.length ? (
            <ShowMedia files={files} onDelete={() => setFiles([])} disabled={isPosting}/>
          ) : (
            <FileUpload
              onChange={(files) => setFiles(Array.from(files).slice(0, 5))}
              disabled={isPosting}
            />
          )}
        </div>
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          disabled={isPosting}
        >
          {isPosting ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            'Post'
          )}
        </button>
      </div>
    </form>
  )
}

export default ReviewForm

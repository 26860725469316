import { useState, useEffect } from 'react'
import * as reviews from '../lib/reviews'

import ReviewForm from './ReviewForm'
import ErrorMessage from './ErrorMessage'
import SuccessMessage from './SuccessMessage'

function useQuery() {
  return new URLSearchParams(window.location.search)
}

const ReviewApp = () => {
  const [reviewInfo, setReviewInfo] = useState(null)

  const [errorMessage, setErrorMessage] = useState('')
  const [submitted, setSubmitted] = useState(false)

  const query = useQuery()
  const reviewId = query.get('l')

  useEffect(() => {
    async function fetch() {
      try {
        if (!reviewId) return setErrorMessage('Oops! Not found.')
        const review = await reviews.get(reviewId)

        if (review.isCompleted)
          setErrorMessage('Psst! This review was DONE.')

        setReviewInfo(review)
      } catch {
        setErrorMessage('Error fetching review info.')
      }
    }
    fetch()
  }, [reviewId])

  const handleSubmit = async ({ reviewerName, rating, reviewText, files }) => {
    try {
      await reviews.post({
        reviewId,
        reviewerName,
        rating,
        reviewText,
        files,
      })
      setSubmitted(true)
    } catch {
      setErrorMessage('Error posting the review')
    }
  }

  if (errorMessage.length > 0) return <ErrorMessage message={errorMessage} />

  if (submitted)
    return (
      <SuccessMessage
        message="Thanks for posting your review!"
        onClose={() =>
          (window.location.href = 'https://www.linkio.app/?from=reviews')
        }
      />
    )

  if (!reviewInfo) return ''

  return (
    <ReviewForm
      providerName={reviewInfo.providerName}
      avatar={reviewInfo.avatar}
      onSubmit={handleSubmit}
    />
  )
}

export default ReviewApp

const Star = (props) => (
  <div className="cursor-pointer" onClick={props.onClick}>
    <svg
      className={`mr-2 w-8 h-8 fill-current ${
        props.selected ? 'text-yellow-500' : ' text-gray-400'
      }`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
    </svg>
  </div>
)

const StarsReview = (props) => {
  return (
    <div className="flex justify-left items-center">
      <div className="flex items-center">
        <Star
          selected={props.rating >= 1}
          onClick={() => {
            props.onRatingChange(1)
          }}
        />
        <Star
          selected={props.rating >= 2}
          onClick={() => {
            props.onRatingChange(2)
          }}
        />
        <Star
          selected={props.rating >= 3}
          onClick={() => {
            props.onRatingChange(3)
          }}
        />
        <Star
          selected={props.rating >= 4}
          onClick={() => {
            props.onRatingChange(4)
          }}
        />
        <Star
          selected={props.rating >= 5}
          onClick={() => {
            props.onRatingChange(5)
          }}
        />
      </div>
    </div>
  )
}

export default StarsReview
